<template>
        <div :class="[
            color ? 'card-shadow-' + color :'card-shadow-primary',
            'widget-chart',
            'widget-chart2',
            'card-btm-border',
            'text-left',
            color ? 'border-' + color : 'border-primary',
            'card'
            ]">
            <div class="widget-chat-wrapper-outer">
                <div class="widget-chart-content">
                    <h6 class="widget-subheading">{{title}}</h6>
                        <div class="widget-numbers mb-0 w-100">
                            <div class="widget-chart-flex">
                                <div class="fsize-2" v-if="formatHtl">
                                    {{value|formatHtl}}
                                    <small v-if="currency" class="opacity-5 ml-1">{{currency}}</small>
                                </div>
                                <div class="fsize-2" v-else>
                                    {{value.toFixed(2)}}
                                    <small v-if="currency" class="opacity-5 ml-1">{{currency}}</small>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: "PlainCard",
    props: [
        "title",
        "value",
        "currency",
        "formatHtl",
        "color"
    ]
}
</script>

<style scoped>

</style>