var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.color ? 'card-shadow-' + _vm.color :'card-shadow-primary',
    'widget-chart',
    'widget-chart2',
    'card-btm-border',
    'text-left',
    _vm.color ? 'border-' + _vm.color : 'border-primary',
    'card'
    ]},[_c('div',{staticClass:"widget-chat-wrapper-outer"},[_c('div',{staticClass:"widget-chart-content"},[_c('h6',{staticClass:"widget-subheading"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"widget-numbers mb-0 w-100"},[_c('div',{staticClass:"widget-chart-flex"},[(_vm.formatHtl)?_c('div',{staticClass:"fsize-2"},[_vm._v(" "+_vm._s(_vm._f("formatHtl")(_vm.value))+" "),(_vm.currency)?_c('small',{staticClass:"opacity-5 ml-1"},[_vm._v(_vm._s(_vm.currency))]):_vm._e()]):_c('div',{staticClass:"fsize-2"},[_vm._v(" "+_vm._s(_vm.value.toFixed(2))+" "),(_vm.currency)?_c('small',{staticClass:"opacity-5 ml-1"},[_vm._v(_vm._s(_vm.currency))]):_vm._e()])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }