<template>
    <div v-if="page">
        <h2>{{$t('hearts-title')}}</h2>
        <div class="row mb-3">
            <div class="col-md-6 col-lg-4 d-block">
                    <plain-card
                        class="mb-3"
                        :title="titles.pool"
                        :value="page.donationStats.poolAmount"
                        :format-htl="true"
                    />
                    <donate-box :tokens="page.viewerTotals.tokens" @update="update"
                                class="mb-3"/>
                    <plain-card
                        :title="titles.user"
                        :value.sync="page.donationStats.userAmount"
                        :format-htl="true"
                        class="mb-3"
                    />
            </div>
            <div class="col-md-6 col-lg-4 d-block">
                    <plain-card
                        :title="titles.next + formatDonationDate(page.donationStats.payoutAt)"
                        :value.sync="page.donationStats.payoutAmount"
                        :format-htl="true"
                        class="mb-3"
                    />
                    <div class="card-shadow-primary mb-3 widget-chart widget-chart2 card-btm-border card-shadow-primary text-left  border-primary card">
                        <div class="mx-5 my-3">
                            <img src="@/assets/images/logo-hearts.png" alt="Hearts foundation" class="w-100" />
                        </div>

                    </div>
            </div>
            <div class="col-md-6 col-lg-4 d-block">
                    <plain-card
                        v-for="donation in page.donationStats.history"
                        :key="donation.date"

                        :title="titles.previous + formatDonationDate(donation.date)"
                        :value="donation.value"
                        :format-htl="true"
                        class="mb-3"
                    />

                </div>
        </div>
    </div>

</template>

<script>
import PlainCard from "@/components/Information/PlainCard";
import {mapGetters} from "vuex";
import DonateBox from "@/components/Information/DonateBox";
import gql from "graphql-tag";
import moment from "moment";
export default {
    name: "HeartsPage",
    components: {DonateBox, PlainCard},
    data() {
        return {
            page: null,
            titles: {
                pool: this.$t("founder-donation"),
                user: this.$t("community-donation"),
                next: this.$t("next-donation"),
                previous: this.$t("previous-donation"),
            }
        }
    },
    methods: {
       formatDonationDate(value) {
           return " " + moment(String(value)).format('D. MMM YYYY')
       },
        update() {
            this.$apollo.queries.page.refetch()
        }
    },
    computed: {
        ...mapGetters(
            {
                founderBonus: 'hearts/founderBonus',
                community: 'hearts/community',
                nextFoundation: 'hearts/nextDonation',
                recent: 'hearts/recent'
            }
        )
    },
    apollo: {
        page: {
            query: gql`query{doc(id:"hearts"){content,title},donationStats{history{date,value},payoutAt,poolAmount,userAmount,payoutAmount},viewerTotals{tokens},viewerIsAdmin}`,
            update: data => data,
            result({data}) {
                if (data) {
                    this.$store.commit('user/updateAvailable', {
                        tokens: data.viewerTotals.tokens
                    })
                    if (data.viewerIsAdmin) {
                        this.$store.commit('user/setAdmin')
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>