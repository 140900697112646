<template>
    <div class="card-shadow-primary mb-3 widget-chart widget-chart2 card-btm-border card-shadow-primary text-left  border-primary card">
        <div class="widget-chat-wrapper-outer">
            <div class="widget-chart-content">
                <h5 class="widget-subheading mb-2 text-unset">{{ $t('donate')}}</h5>
                <div class="widget-numbers mb-0 w-100">
                    <div class="widget-chart-flex">

                        <div class="input-group">
                            <input placeholder="Amount" step="0.01" type="number" class="form-control" :min=0 :max="tokens" v-model="donation" />
                            <div class="input-group-append">
                                <span class="input-group-text">HTL</span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-1">
                        <v-btn color="primary" small class="ma-0 text-unset" @click="donate" >{{ $t('donate')}}</v-btn>
                    </div>
                </div>
            </div>

        </div>
        <v-snackbar
            v-model="donationResponse"
            :timeout="timeout"
            color="primary"
            absolute
            right
            rounded="pill"
        >
            {{$t('donation', [lastDonation])}}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="donationResponse = false"
                >
                    {{$t('close')}}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "DonateBox",
    props: [
        "tokens"
    ],
    data() {
        return {
            donation: 0,
            donationResponse: false,
            lastDonation: 0,
            timeout: 2000,
        }
    },
    methods: {
        async donate() {
            await this.$apollo.mutate({
                // Query
                mutation: gql`mutation($amount:Float!){donateTokens(amount:$amount)}`,
                // Parameters
                variables: {
                    amount: this.donation * 1.0,
                },
            }).then(result => {
                this.lastDonation = this.donation
                this.donation = 0
                this.donationResponse = result.data
                this.$emit('update')
            })
        }
    }
}
</script>

<style scoped>

</style>